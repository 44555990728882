@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Overview {
	position: relative;
	min-height: 100%;
	color: white;
	background-image: url('../../assets/images/background2.jpg');
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	.Overview-gameInfo {
		padding: 1.11em 2.22em;
		@include flex('space-between', 'center');
		.Overview-infoBox {
			font-size: 0.8889em;
			width: 11.875em;
			height: 3.75em;
			border-radius: 0.3125em;
			color: white;
			background-color: #3d5762;
			padding: 0.5em;
			flex-grow: 1;
			margin: 0 1em;
		}
	}
	.Overview-buttons {
		position: relative;
		width: 100%;
		height: 5em;
		@include flex('center', 'center');
		.Overview-nextPhase {
			background:$grey-offset;
			display: inline-block;
			width: 30.5em;
			height: 4em;
			color: white;
			border-radius: 0.25em;
			padding: 0.8em;
			margin:0.5em 0;
			@include flex(space-between, center);
			&-right{
				@include flex(space-between, center);
			}
			&.endgame{
				width: 16em;
				@include flex(center, center);
				text-transform: uppercase;
			}
			.Overview-nextPhaseText {
				font-size:1.2em;
				font-weight: bold;;
				font-style: italic;
				strong {font-style: normal;}
			}
			.Overview-nextPhaseBtn {
				background-color: $blue;
				box-shadow: 0 0.22em 0.33em 0em rgba(0, 0, 0, 0.16);
				cursor: pointer;
				padding: 0.3em 1.3em;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 0.3em;
				&.paused{
					@include opacity(0.5);
					cursor: not-allowed;
				}
				&:hover {
					background-color: darken($blue, 3%);
				}
				div{
					font-size: 1.2em;
					font-weight: bolder;
					font-style: italic;
				}
			}
		}
		.Overview-statusPopupBtn {
			background:$grey-offset;
			width: 8.25em;
			height: 4em;
			color: white;
			border-radius: 0.25em;
			padding: 0em;
			margin: 0.5em 0 0.5em 1em;
			text-align: center;
			cursor: pointer;
			span {
				@include opacity(0.3);
				font-size: 1.2em;
				line-height: 3.33;
				font-weight: bolder;
				font-style: italic;
			}
			&.enabled span {
				@include opacity(1);
			}
		}
	}
	.Overview-pauseBtn {
		position: absolute;
		right: 2.22em;
		top: 13em;
		min-width: 6.67em;
		padding: 0 1em;
		height: 2.22em;
		border-radius: 0.25em;
		color: #213640;
		background-color: #e8c039;
		cursor: pointer;
		&:hover {
			background-color: darken(#e8c039, 5%);
		}
		&--active {
			background-color: $blue;
			&:hover {
				background-color: darken($blue, 5%);
			}
		}
		span {
			font-size: 1.389em;
			font-weight: 900;
			font-style: italic;
			text-transform: uppercase;
			line-height: 1.6;
		}
	}


	.Overview-groups {
		display: inline-block;
		vertical-align: top;
		width: 16.5em;
		margin-left: 2.22em;
		margin-top: 1.25em;
		background: rgba(#213640, 0.8);
		border-radius: 0.25em 0 0 0.25em;
		border-right: 1px solid white;
		.Overview-groupsHeader {
			width: 100%;
			height: 4.44em;
			background-color: #213640;
			border-radius: 0.25em 0 0 0;
			cursor: pointer;
			@include flex('center', 'center');
			span {
				font-size: 1.33em;
				font-weight: 500;	
			}
		}
		.Overview-groupsBody {
			padding: 1em 0 0 1em;
			.Overview-group {
				position: relative;
				margin-bottom: 1em;
				@include flex('left', 'center');
				.Overview-groupTitle {
					width: 11.5em;
					height: 2.75em;
					line-height: 1.25;
					padding: 0.75em 1em;
					color: rgba(white, 0.5);
					background-color: rgba(white, 0.1);
					border: 1px solid rgba(white, 0.5);
					border-radius: 0.25em;
					&.playing {
						color: white;
						border-color: white;
					}
				}
				.Overview-groupPlayers {
					display: none;
					position: absolute;
					left: 12em;
					min-width: 12em;
					background-color: #213640;
					border-radius: 0.25em;
					padding: 0.5em;
					z-index: 10;
				}
				.Overview-groupTitle:hover ~ .Overview-groupPlayers {
					display: block;
				}
				.Overview-groupPlaying {
					width: calc(100% - 12em);
					height: 2.75em;
					margin-left: 0.5em;
					background-color: rgba(white, 0.1);
					border: 1px solid white;
					border-right: none;
					border-radius: 0.25em 0 0 0.25em;
					background-image: url('../../assets/images/icon-eye.svg');
					background-size: 1.963em auto;
					background-position: center center;
					background-repeat: no-repeat;
					cursor: pointer;
				}
			}
		}
	}
	.Overview-gameSteps {
		display: inline-block;
		vertical-align: top;
		position: relative;
		width: calc(100% - (16.5em + 2*2.22em));
		margin-top: 1.25em;
		background: rgba(#213640, 0.8);
		border-radius: 0 0.25em 0.25em 0;
		overflow: auto hidden;
		@include custom-scrollbar(0.5em, $black, $grey);
		.Overview-gameStepsHeader {
			width: 100%;
			height: 4.44em;
			white-space: nowrap;
			background-color: #213640;
			border-radius: 0.25em 0 0 0;
			.Overview-gameStepsHeaderSection {
				display: inline-block;
				vertical-align: top;
				width: 18em;
				height: 100%;
				text-align: center;
				background-color: $grey-offset;
				padding:0.3em;
				&.currentPhase{
					background-color: $green;
				}
				&.futurePhase {
					opacity: 0.5;
					background-color: $grey-offset;
				}
				&.steps-1 {
					width: 12em;
					.Overview-gameStepsHeaderSectionSteps .Overview-gameStepsHeaderSectionStep {
						width: 100%;
					}
				}
				&.steps-2 {
					width: 14em;
					.Overview-gameStepsHeaderSectionSteps .Overview-gameStepsHeaderSectionStep {
						width: 50%;
					}
					&.de {
						.Overview-gameStepsHeaderSectionSteps .Overview-gameStepsHeaderSectionStep {
							&:nth-child(1) {
								width: 25%;
							}
							&:nth-child(2) {
								width: 75%;
							}
						}
					}
				}
				&.steps-3 {
					.Overview-gameStepsHeaderSectionSteps .Overview-gameStepsHeaderSectionStep {
						width: 33%;
					}
					&.de {
						.Overview-gameStepsHeaderSectionSteps .Overview-gameStepsHeaderSectionStep {
							&:nth-child(1) {
								width: 17%
							}
							&:nth-child(2) {
								width: 27%;
							}
							&:nth-child(3) {
								width: 56%;
							}
						}
					}
				}
				&.steps-4 {
					.Overview-gameStepsHeaderSectionSteps .Overview-gameStepsHeaderSectionStep {
						width: 25%;
					}
				}
				&.de.phase-6,
				&.de.phase-7 {
					.Overview-gameStepsHeaderSectionTitle {
						font-size: 1.2em;
						line-height: 0.9;
						white-space: wrap;
					}
				}

				.Overview-gameStepsHeaderSectionTitle {
					width: 100%;
					height: 50%;
					font-size: 1.33em;
					line-height: 1.67;
					font-weight: 500;
				}
				.Overview-gameStepsHeaderSectionSteps {
					@include flex('center', 'center');
					.Overview-gameStepsHeaderSectionStep {
						width: 33.33%;
						line-height: 2.22em;
					}
				}
			}
		}
		.Overview-gameStepsBody {
			padding: 1em 1em 0 0;
			.Overview-gameStepsGroup {
				width: 100%;
				height: 2.75em;
				margin-bottom: 1em;
				white-space: nowrap;
				// &:last-child {
				// 	margin-bottom: 0.5em;
				// }
				.Overview-groupContainer {
					display: inline-block;
					vertical-align: top;	
					height: 2.75em;
					background-color: rgba(white, 0.1);
					border: 1px solid transparent;
					border-left: none;
					border-radius: 0 0.25em 0.25em 0;
					&.playing {
						border-color: white;
					}
					> div {
						display: inline-block;
						vertical-align: top;
						height: 100%;
					}
					.Overview-groupPhase {
						height: 100%;
						&.steps-1 {
							width: 12em;
							.Overview-groupSection {width: 100%;}
						}
						&.steps-2 {
							.Overview-groupSection {width: 7em;}
						}
						&.steps-3 {
							.Overview-groupSection {width: 6em;}
						}
						&.steps-4 {
							.Overview-groupSection {width: 4.5em;}
						}
						&.last {
							width: 11em;
							.Overview-groupSection {width: 100%;}
						}
						.Overview-groupSection {
							display: inline-block;
							vertical-align: top;
							width: 4.5em;
							height: 100%;
							background-size: 1.44em auto;
							background-position: center center;
							background-repeat: no-repeat;
							background-image: url('../../assets/images/icon-hourglass.svg');
							&.done {
								background-size: 1em auto;
								background-image: url('../../assets/images/icon-check.svg');
							}

						}
					}
				}
			}
		}
		.Overview-phaseLines {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			bottom:0;
			pointer-events: none;
			.Overview-phaseLine {
				position: absolute;
				top: 0;
				bottom: 0;
				width: 0;
				border-right: 1px solid white;
				&--1 {left: calc(18em);}
				&--2 {left: calc((2 * 18em));}
				&--3 {left: calc((2 * 18em) + 12em);}
				&--4 {left: calc((2 * 18em) + (2 * 12em));}
				&--5 {left: calc((2 * 18em) + (3 * 12em));}
				&--6 {left: calc((2 * 18em) + (4 * 12em));}
				&--7 {left: calc((2 * 18em) + (5 * 12em));}
				&--8 {display: none;}
			}

			&.safetytraining {
				.Overview-phaseLine { 
					&--1 {left: calc(18em);}
					&--2 {left: calc((18em + 12em));}
					&--3 {left: calc(18em + 12em + 14em);}
					&--4 {left: calc(18em + 2 * 12em + 14em);}
					&--5 {left: calc(18em + 2 * 12em + 2 * 14em);}
					&--6 {left: calc(18em + 3 * 12em + 2 * 14em);}
					&--7 {left: calc(18em + 4 * 12em + 2 * 14em);}
				}
			}
			&.crm-helicopters {
				.Overview-phaseLine { 
					&--1 {left: calc(12em);}
					&--2 {left: calc((2 * 12em));}
					&--3 {left: calc((2 * 12em + 14em));}
					&--4 {left: calc((3 * 12em + 14em));}
					&--5 {left: calc((4 * 12em + 14em));}
					&--6 {left: calc((5 * 12em + 14em));}
					&--7 {left: calc((6 * 12em + 14em));}
					&--8 {left: calc((6 * 12em + 2 * 14em)); display: block;}
					&--9 {left: calc((6 * 12em + 3 * 14em));}
					&--10 {left: calc((6 * 12em + 4 * 14em));}
					&--11 {left: calc((7 * 12em + 4 * 14em));}
					&--12 {display: none;}
				}
			}
		}
	}
}