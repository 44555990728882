@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.CrewExperience {
	position: relative;
	width: 14em;
	color: white;
	background-color: rgba(#213640, 0.4);
	border-radius: 0.25em;
	padding-bottom: 0.5em;
	.CrewExperience-title {
		height: 2em;
		border-radius: 0.25em 0.25em 0 0;
		background-color: #30AD87;
		margin-bottom: 0.75em;
		@include flex('center', 'center');
		span {
			font-weight: 700;
			font-style: italic;
	
		}
	}
	.CrewExperience-experience {
		margin: 0.25em 0.75em;
		@include flex('space-between', 'center');
		.CrewExperience-experienceTitle {
			font-size: 0.85em;
			font-weight: 600;
		}
		.CrewExperience-experienceFM {
			width: 4.75em;

			@include flex('space-between', 'center');
			.CrewExperience-value {
				height: calc(0.67em + 1px);
				width: calc(0.67em + 1px);
				border-radius: calc(0.67em + 1px);
				background-color: $white;
				&.filled {
					background-color: #30AD87;
				}
			}
		}
	}
}