@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';

.Login {
	position: relative;
	min-height: 100%;
	background-image: 
		url('../../assets/images/background-plane.png'),
		url('../../assets/images/background.jpg');
	background-size: 32em auto, cover;
	background-position: center center, top center;
	background-repeat: no-repeat;
	text-align: center;
	@include flex('center', 'center');
	flex-direction: column;
	.Login-logo {
		width: 98%;
		height: 3em;
		// background-color: red;
		background-image: url('../../assets/images/logo-cgl.svg');
		background-size: 10% auto;
		background-position: bottom right;
		background-repeat: no-repeat;
		margin-top: 3em;
	}
	.Login-link {
		cursor: pointer;
		.Login-aeroteamLogo  {
			position: absolute;
			top: 1.3em;
			left: 1em;
			height: 4em;
			image-rendering:-moz-crisp-edges;
			image-rendering:-o-crisp-edges; 
			image-rendering:-webkit-optimize-contrast; 
			image-rendering:optimize-contrast;        
			-ms-interpolation-mode:nearest-neighbor; 
		}
	}

	.Login-language {
		position: absolute;
		top: 1em;
		right: 1em;
		width: 8em;
	}

	.LoginContainer {
		// margin-top: 8em;
		display: inline-block;
		text-align: left;
		min-height: 16.5em;
		&.group {
			padding-bottom: calc(0.9 * 1.5em);
		}
		.Login-gameTitle {
			color: #FFF200;
			text-align: center;
			font-weight: 600;
			margin-bottom: 2em;
		}
		.Login-tabContainer {
			width: 100%;
			height: 3.6em;
			@include flex('center', 'center');
			.Login-tabButton {
				height: 100%;
				width: 50%;
				font-size: 1.2em;
				font-weight: bold;
				border-radius: 0.25em 0.25em 0 0;
				background-color: $black-seeThrough;
				cursor: pointer;
				@include flex('center', 'center');
				&.active {
					background-color: $green-dark;
					color: white;
				}
			}
		}
	}
	.Login-formContainer {
		.LoginFacilitator,
		.LoginGroup {
			width: 22.25em;
			text-align: left;
			background-color: $black-seeThrough;
			border: 0.35em solid $green-dark;
			border-radius: 0 0 0.25em 0.25em;

			.Login-form {
				position: relative;
				height: 12.25em;
				padding: 1.2em;
				&.feedback {
					.Login-infoLink {
						color: white;
						cursor: pointer;
						&:hover {text-decoration: underline;}
					}
				}
				.Login-info {
					color: white;
					font-size: 0.95em;
					margin-top: 0;
					line-height: 1.5;
					margin-bottom: 1.5em;
					
				}
				input {
					width: 13.85em;
					height: 1em;
					border: none;
					border-radius: 0.35em;
					display: block;
					padding: 1em 1em 1em 0.5em;
					margin: auto;
					margin-bottom: 1.2em;
					background-color: $black;
					color: $white;
					&::placeholder {color: rgba(255, 255, 255, 0.5)}
					&:nth-child(2) {
						margin-bottom: 0.5em;
					}
				}
			}	
		}
		.LoginGroup {
			.Login-form {padding-top: 2.8em;}
		}
	}
}

.Login-loadingWrapper {
	height: 12.25em;
	background-image: url('../../assets/images/icon-loading.svg');
	background-size: auto 10em;
	background-position: center center;
	background-repeat: no-repeat;
}


.LoginChooseGroup {
	width: 69em;
	min-height: 15em;
	background-color: $black-seeThrough;
	border: 0.35em solid $green-dark;
	overflow: auto;
	scrollbar-width: thin;
	@include custom-scrollbar(0.5em, $black, $white-seeThrough);
	.LoginChooseGroupWrapper {
		min-width: 100%;
		white-space:nowrap;
		padding: 1em;
		.Login-groupSelectBtn {
			width: 15em;
			display: inline-block;
			border: none;
			color: $white;
			background-color: $black;
			margin: 1em;
			border-radius: 0.25em;
			cursor: pointer;
			&:hover {
				@include box-shadow(0, 0, 0.22em, 1px, $white-seeThrough);
			}
			.Login-groupSelectTitle {
				height: 2.3em;
				padding: 0.55em;
				border-radius: 0.25em 0.25em 0 0;
				font-size: 1.2em;
				font-weight: bolder;
				color: $white;
				background-color: $blue-dark;
			}
			.Login-groupSelectPlayers {
				display: flex;
				align-items: flex-start;
				padding: 0.5em;
				overflow: hidden;
			}
		}
	}
}

.Login-errorMessage {
	height: 1.5em;
	margin: 0 1em 0.6em 1em;
	font-size: 0.85em;
	color: $red-light;
	text-align: center;
}

.Login-resetPasswordBtn {
	height: 1.5em;
	font-size: 0.9em;
	text-align: right;
	color: white;
	cursor: pointer;
}