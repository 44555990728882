@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.CrewIssues {
	position: relative;
	width: 14em;
	background-color: rgba(#213640, 0.4);
	border-radius: 0.25em;
	padding-bottom: 0.5em;
	.CrewIssues-title {
		height: 2em;
		border-radius: 0.25em 0.25em 0 0;
		color: #EBD03C;
		background-color: #213640;
		margin-bottom: 0.75em;
		@include flex('center', 'center');
		span {
			font-weight: 700;
			font-style: italic;
	
		}
	}
	.CrewIssues-issue {
		width: 100%;
		height: 2.44em;
		border-radius: 0.33em;
		color: #213640;
		background-color: #E8C039;
		@include box-shadow(0, -1px,0.45em, 0em, #222);
		@include flex('center', 'center');
		span {
			font-size: 0.85em;
			font-weight: 600;
		}
	}
}