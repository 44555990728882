@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.LandingPage {
	position: relative;
	min-height: 100%;
	background-image:url('../../assets/images/background-clouds.jpg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	padding-top: 2em;
	text-align: center;
	.LandingPage-logo {
		width: 9.5em;
		height: 3em;
		// background-color: red;
		background-image: url('../../assets/images/logo-cgl.svg');
		background-size: 100% auto;
		background-position: bottom right;
		background-repeat: no-repeat;
		margin-top: 2em;
		margin-left: 65em;
	}
.sagroup {
	position: absolute;
	top: 1.3em;
	right: 1em;
	height: 4em;
}
.aeroteam {
	position: absolute;
	top: 1.3em;
	left: 1em;
	height: 4em;
	image-rendering:-moz-crisp-edges;
	image-rendering:-o-crisp-edges; 
	image-rendering:-webkit-optimize-contrast; 
	image-rendering:optimize-contrast; 
	-ms-interpolation-mode:nearest-neighbor; 
}
	.LandingPage-games{
		@include flex(space-between, center);
		&:before,
		&:after {
			content: '';
			display: block;
		}
		.LandingPage-game {
			display: block;
			text-decoration: none;
			width: 15.5em;
			padding-bottom: 11em;
			border-radius: 0.3em;
			cursor:pointer;
			&.crm-aeroplanes {
				background-image: url('../../assets/images/faded-plane.png'), url('../../assets/images/background.jpg');
				background-size: 9em, cover;
				background-position: center top 5.5em, center;
				background-repeat: no-repeat;
				@include box-shadow(0, -1px, 1em, 0em, #222);
			}
			&.crm-helicopters {
				background-image: url('../../assets/images/helicopter.png'), url('../../assets/images/circle.png'), url('../../assets/images/background-clouds.jpg') ;
				background-size: 8em, 8em, cover;
				background-position: center top 6em, center top 6em, center;
				background-repeat: no-repeat;
				@include box-shadow(0, -1px, 1em, 0em, #222);
			}
			&.safetytraining {
				background-image: url('../../assets/images/faded-plane.png'), url('../../assets/images/hangar.png'), url('../../assets/images/background.jpg');
				background-size: 7em, 13em, cover;
				background-position: center top 8em, center, center;
				background-repeat: no-repeat;
				@include box-shadow(0, -1px, 1em, 0em, #222);
				.LandingPage-gameTitle { 
					padding: 1em 0;
				}
			}
			.LandingPage-gameTitle {
			display: block;
			text-decoration: none;
			min-height: 4.7em;
			text-align: center;
			color:$mayday-yellow;
			font-weight: bold;
			padding:1em;
			margin-top: 0;
			border-radius: 0.3em 0.3em 0 0;
			background: #213640; /* Old browsers */
			background: -moz-linear-gradient(top, #213640 0%, #3d5762 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top, #213640 0%,#3d5762 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, #213640 0%,#3d5762 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#213640', endColorstr='#3d5762',GradientType=0 ); /* IE6-9 */
			}
		}	
	}

	.LandingPage-adminBtn {
		position: absolute;
		left: 1em;
		bottom: 1em;
		font-size: 1.11em;
		line-height: 2;
		height: 2em;
		color: white;
		background-color: $green;
		text-transform: none;
		padding: 0em 2.5em;
		text-decoration: none;
		text-align: center;
		border: 1px solid white;
		border-radius: 0.25em;
		&:hover {background-color: darken($green, 3%);}
	}
}