@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.CrewFeedbackST {
	min-height: 100%;
	background-image: url('../../assets/images/background2.jpg');
	background-size: cover;
	background-attachment: initial;
	background-position: center center;
	background-repeat: no-repeat;
	color: white;

	.CrewFeedbackST-crew {
		width: 100%;
		padding: 8em 2em 2em 2em;
		.CrewFeedbackST-slot {
			position: relative;
			display: inline-block;
			vertical-align: top;
			width: 13.5em;
			height: 13.85em;
			margin: 0.25em 0.5em;
			background-image: linear-gradient(rgba(black, 0.24), rgba(black, 0.07));
			border-radius: 0.33em;
			text-align: center;
			&.shake {
				-webkit-animation: wobble 5s cubic-bezier(0.280, 0.840, 0.420, 1) 3s infinite forwards;
				animation: wobble 5s cubic-bezier(0.280, 0.840, 0.420, 1) 3s infinite forwards;
			}
		}
	}

	&.de {
		.CrewFeedbackST-crew {
			.CrewFeedbackST-slot {
				height: 17em;
			}
		}
	}
}