@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.CrewCardFront {
	position: relative;
	width: 100%;
	height: 100%;
	display: inline-block;
	text-align: left;	
	background-color: #213640;
	border-radius: 0.33em;
	cursor: pointer;
	&.crewPopupSmall,
	&.crewFeedback,
	&.crewFeedbackWhite,
	&.crewFeedbackSelected,
	&.crewPopup {
		.CrewCardFront-bottom {
			background-image: linear-gradient(#3D5762, #213640);
			background-position: center center;
			background-size: auto;
		}
	}
	&.crewPopupSmall,
	&.crewFeedback,
	&.crewFeedbackWhite {
		.CrewCardFront-top {
			background-position: top 1.25em center;
		}
	}
	&.crewFeedbackWhite {
		.CrewCardFront-top {
			background-color: white;
			border-radius: 0.33em;
			&.cockpit {background-image: url('../../assets/images/icon-crew-cockpit-grey.svg');}
			&.cabin {background-image: url('../../assets/images/icon-crew-cabin-grey.svg');}
			&.pilot {
				background-image: url('../../assets/images/icon-pilot-m-dark.svg');
				&.f {background-image: url('../../assets/images/icon-pilot-f-dark.svg');}
			}
			&.tech {background-image: url('../../assets/images/icon-tech-dark.svg');}
			&.doctor {background-image: url('../../assets/images/icon-doctor-dark.svg');}

		}
	}

}

.CrewCardFront-top {
	height: 6em;
	background-size: 3.75em auto;
	background-position: top 1.25em right 1.25em;
	background-repeat: no-repeat;
	&.cockpit {background-image: url('../../assets/images/icon-crew-cockpit.svg');}
	&.cabin {background-image: url('../../assets/images/icon-crew-cabin.svg');}
	&.pilot {
		background-image: url('../../assets/images/icon-pilot-m.svg');
		&.f {background-image: url('../../assets/images/icon-pilot-f.svg');}
	}
	&.tech {background-image: url('../../assets/images/icon-tech.svg');}
	&.doctor {background-image: url('../../assets/images/icon-doctor.svg');}
}

.CrewCardFront-bottom {
	position: absolute;
	top: 5.75em;
	bottom: 0em;
	left: 0;
	width: 100%;
	background-image: linear-gradient(#3D5762, #213640);
	border-bottom-left-radius: 0.33em;
	border-bottom-right-radius: 0.33em;
	&.cockpit, 
	&.cabin,
	&.pilot,
	&.tech,
	&.doctor {
		background-size: 3.75em auto, auto;
		background-position: top 1.25em right 1.25em, center center;
		background-repeat: no-repeat;
	}
	&.cockpit {background-image: url('../../assets/images/icon-crew-cockpit.svg'), linear-gradient(#3D5762, #213640);}
	&.cabin {background-image: url('../../assets/images/icon-crew-cabin.svg'), linear-gradient(#3D5762, #213640);}
	&.pilot {
		background-image: url('../../assets/images/icon-pilot-m.svg'), linear-gradient(#3D5762, #213640);
		&.f {background-image: url('../../assets/images/icon-pilot-f.svg'), linear-gradient(#3D5762, #213640);}
	}
	&.tech {background-image: url('../../assets/images/icon-tech.svg'), linear-gradient(#3D5762, #213640);}
	&.doctor {background-image: url('../../assets/images/icon-doctor.svg'), linear-gradient(#3D5762, #213640);}
}

.CrewCardFront-info {
	text-align: left;
	padding: 0.8em;
}
.CrewCardFront-name,
.CrewCardFront-title,
.CrewCardFront-age {
	white-space: nowrap;
	font-size: 0.75em;
	line-height: 2em;
	@include no-select();
	span {font-weight: 700;}
}

.CrewCardFront-description {
	font-size: 0.75em;
	line-height: 1.65;
	padding: 1em;
	p {margin: 0;}	
}