@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Settings {
	position: relative;
	min-height: 100%;
	background-image: url('../../assets/images/background2.jpg');
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	.Settings-companyTitle {
		font-size: 1.25em;
		color: white;
		font-weight: bold;
		margin-bottom: 0.5em;
		margin-top: 2em;
		&:nth-child(1) {margin-top: 0;}
	}

	.Settings-adminBtn {
		position: absolute;
		left: 4.25em;
		top: 4.85em;
		line-height: 2;
		height: 2em;
		width: 10em;			
		background-color: $green;
		border: 1px solid white;
		border-radius: 0.25em;
		text-transform: none;
		text-decoration: none;
		@include flex('center', 'center');
		&:hover {background-color: darken($green, 3%);}
		span {
			color: white;
			font-size: 1.11em;	
		}
	}

	.Settings-toggleGamesBtn {
		position: absolute;
		top: 4.85em;
		right: 2em;
		display: inline-block;
		border: 4px solid white;
		cursor: pointer;
		
		.Settings-toggleGamesText {
			display: inline-block;
			vertical-align: top;
			width: 7.556em;
			height: 2.25em;
			line-height: 2.25em;
			text-align: center;
			color: white;
			font-weight: 700;
			@include no-select();
			&.selected {
				background-color: #159d69;
				&:hover {
					background-color: #159d69;
				}
			}
			&:hover {
				background-color: rgba(#159d69, 0.5);
			}
		}
	}
	.Settings-games {
		padding: 4.5em 0 2em calc(50% - 34em);
		.Settings-game {
			display: inline-block;
			vertical-align: top;
			width: 15.5em;
			margin: 0 2em 2em 0;
			color: white;
			background-color: rgba(#213640, 0.8);
			margin-bottom: 2em;
			border-radius: 0.25em;
			&.started {
				.Settings-gameHeader {
					cursor: pointer;
				}
				.Settings-gameBody {
					.Settings-playBtn {
						background-color: #0080c9;
						&:hover {
							background-color: darken(#0080c9, 5%);
						}
					}
					.Settings-editBtn {
						color: rgba(white, 0.5);
						background-color: #50545a;
						&:hover {
							background-color: darken(#50545a, 3%);
						}
					}
				}
			}
			&.disabled {
				.Settings-gameHeader {
					cursor: default;
					background-color: #213640;
				}
				.Settings-gameBody {
					.Settings-playBtn,
					.Settings-editBtn {
						cursor: not-allowed;
						color: rgba(white, 0.5);
						background-color: #50545a;
						&:hover {background-color: #50545a;}
					}
				}
			}
			.Settings-gameHeader {
				background-color: #52AEE8;
				border-top-left-radius: 0.25em;
				border-top-right-radius: 0.25em;
				height: 2em;
				&.dark {background-color: #226ca8;}
				.Settings-gameTitle{
					white-space: nowrap !important;           
					overflow: hidden;         
					word-wrap: normal !important;
					text-overflow: ellipsis !important;
					-ms-text-overflow: ellipsis !important;
				}
				.Settings-gameTitle,
				.Settings-gameDate {
					display: inline-block;
					vertical-align: middle;
					width: 60%;
					height: 100%;
					text-align: left;
					padding: 0 0.5em;
					font-weight: bold;
					line-height: 2;
					white-space: nowrap;
				}
				.Settings-gameDate {
					width: 40%;
					text-align: right;
					font-weight: normal;
				}
			}
			.Settings-gameBody {
				position: relative;
				padding: 0.55em;
				.Settings-playBtn,
				.Settings-editBtn {
					display: inline-block;
					vertical-align: top;
					width: calc(50% - 0.25em);
					text-align: center;
					text-transform: uppercase;
					color: white;
					background-color: #288e73;
					font-weight: 900;
					font-style: italic;
					border-radius: 0.25em;
					line-height: 2em;
					cursor: pointer;
					@include no-select();
					&:hover {
						background-color: darken(#288e73, 5%);
					}
				}

				.Settings-editBtn {
					margin-left: 0.5em;
					color: #213640;
					cursor: pointer;
					background-color: #ebd03c;
					&:hover {
						background-color: darken(#ebd03c, 5%);
					}
				}
				.Settings-gameCode {
					margin-top: 0.5em;
					display: inline-block;
					vertical-align: middle;
					span {
						display: inline-block;
						vertical-align: middle;
						background-color: #3d5762;
						border-radius: 0.25em;
						padding: 0.25em 0.5em;
					}
				}
				.Settings-resetBtn {
					font-size: 1.8em;
					position: absolute;
					bottom: 0.7em;
					right: 1.2em;
					width: 0.75em;
					height: 0.75em;
					text-align: right;
					cursor: pointer;
					@include no-select();
					color: #ebd03c;
				}
				.Settings-deleteBtn {
					position: absolute;
					bottom: 0.55em;
					right: 0.55em;
					width: 1.5em;
					height: 1.5em;
					background-image: url('../../assets/images/icon-trash.svg');
					background-size: 1.1em auto;
					background-position: bottom right;
					background-repeat: no-repeat;
					cursor: pointer;
				}
			}
		}
	}
}