@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.CrewStatus {
	display: inline-block;
	vertical-align: top;
	background-color: $black-seeThrough;
	border-radius: 0.25em;
	width: 14.56em;
	cursor: pointer;
	.CrewStatus-title {
		border-radius: 0.25em 0.25em 0 0;
		height: 2em;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 700;
		font-style: italic;
		background-color: $grey;
	}
	.CrewStatus-content {
		display: flex;
		padding: 0.885em 2em;
		height: 3.56em;
		justify-content: space-around;

		.CrewStatus-icon {
			width: 1.22em;
			background-size: contain;
			background-position: bottom;
			background-repeat: no-repeat;
			&.cockpit {background-image: url('../../assets/images/icon-crew-cockpit.svg');}
			&.cabin {background-image: url('../../assets/images/icon-crew-cabin.svg');}
			&.pilot {
				background-image: url('../../assets/images/icon-pilot-m.svg');
				&.f {background-image: url('../../assets/images/icon-pilot-f.svg');}
			}
			&.sysop,
			&.paramedic {
				background-image: url('../../assets/images/icon-tech.svg');
			}
			&.doctor {
				background-image: url('../../assets/images/icon-doctor.svg');
			}
			&--removed {
				@include opacity(0.3);
			}
		}
	}

	&.crm-helicopters {
		width: 14.56em;
		.CrewStatus-title {
			background-color: #3D5762;
		}
	}
}
