@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.InfoMapPopup {
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(170, 79, 79, 0.1);
	z-index: 20;
	@include blurBackground(2px);
	@include flex('center', 'center');
	.InfoMapPopup-content {
		position: relative;
		width: 96vw;
		height: 96vh;
		background-color: rgba(33,54,64,0.75);
		border-radius: 0.3em;
		.InfoMapPopup-header {
			position: relative;
			height: 3.5em;
			background-color: #3d5762;
			border-top-left-radius: 0.3em;
			border-top-right-radius: 0.3em;
			@include flex('center', 'center');
			.InfoMapPopup-title {
				font-size: 1.55em;
				line-height: 1;
				color: white;
				font-style: italic;
				font-weight: 800;
				text-align: center;
			}
			.InfoMapPopup-closeBtn {
				position: absolute;
				top: 0;
				right: 0;
				width: 3.65em;
				height: 3.65em;
				background-image: url('../../assets/images/icon-close.svg');
				background-size: 1.25em auto;
				background-position: center center;
				background-repeat: no-repeat;
				cursor: pointer;
			}
		}
		.InfoMapPopup-body {
			position: relative;
			width: 100%;
			height: calc(100% - 3.5em);
			bottom: 0;
			overflow: auto;
			padding: 1em 0 1em 1em;
			@include custom-scrollbar(0.5em, $black, $grey);
			.InfoMapPopup-sidebar {
				display: inline-block;
				vertical-align: top;
				width: 30%;
				.InfoMapPopup-missionInfo {
					width: calc(100% - 1em);
					height: 16em;
					max-height: 16em;
					padding: 1em 0.5em 1em 1em;
					border-radius: 0.3em;
					background-image: linear-gradient(#30ad87, #288e73);
					.InfoMapPopup-missionInfoContent {
						width: 100%;
						height: 100%;
						max-height: 100%;
						overflow: auto;
						padding-right: 0.5em;
						@include custom-scrollbar(0.5em, #213640, #6A6F77);
						.InfoMapPopup-briefing {
							padding: 0; 
							margin-bottom: 1em;
							color: #fff;
							font-size: 0.9em;
							 p {
								margin: 0;
							}
							&.framed {
								border: 0.15em solid #fff;
								-webkit-animation: borderBlink 0.4s ease-in-out 3;    
								animation: borderBlink 0.4s ease-in-out 3; 
								border-radius: 0.2em;
								padding: 0.5em;
							}
						}						
					}
					
				}
				.InfoMapPopup-locations {
					width: calc(100% - 1em);
					.InfoMapPopup-locationsHeader {
						height: 3em;
						@include flex(space-between);
						.InfoMapPopup-location {
							display: inline-block;
							cursor: pointer;
							background: $black;
							border-radius: 0.3em;
							margin: 0.5em 0;
							padding: 0 0.5em;
							color:#fff;
							font-weight:bold;
							svg {
								width: 1.25em;
								margin-right:0.3em;
							}
							@include flex( center, center );
							@include opacity(0.5);
							&.base svg {
								width: 1.56em;
							}
							&:hover {@include opacity(0.8);}
							&.selected {@include opacity(1);}
						}
					}
					.InfoMapPopup-locationsBody {
						background: $black;
						border-radius: 0.3em;
						padding:0.5em;
						overflow: auto;
						@include custom-scrollbar(0.5em, $black, $grey);			
						p {
							padding:0; 
							margin:0;
							font-size: 0.9em;
							color:#fff;
						}
					}
				}
			}
			.InfoMapPopup-map {
				display: inline-block;
				vertical-align: top;
				position: relative;
				width: 50em;
				height: 32em;
				background-image: url('../../assets/images/helicopter-map.jpg');
				background-size: contain;
				background-position: top center;
				background-repeat: no-repeat;
				svg {
					position: absolute;
					right: 0.267em;
					bottom: 1.1em;
					width: 97.79%;
				}
				&.hide-incident-site {
					svg {
						#Incident_site {
							display: none;
						}
					}
				}
			}

			.InfoMapPopup-locationInfoPopup {
				position: absolute;
				top: 0.5em;
				right: 0.5em;
				width: 15em;
				color: white;
				border-radius: 0.25em;
				background-color: #3d5762;
				padding: 0.5em;
				cursor: pointer;
				p {
					margin: 0;
					font-size: 0.9em;
				}
			}
		}
	}
}